import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { useWindowSize } from '../utils/hooks'

const ContactUs = ({
  catchPhrase = 'Ready to accelerate your business?',
  sectionClass,
  includeRequestADemo = true,
}) => {
  const windowWidth = useWindowSize()
  return (
    <section className={sectionClass}>
      <div className="call-to-action-bg statement">
        <div className="content">
          <div className="container">
            <div className="ta-c">
              <h3 className="has-text-dark is-marginless h-fc">
                {catchPhrase}
              </h3>
            </div>
            <div
              className={`is-hcentered horizontal-list ${
                catchPhrase && catchPhrase.length > 0 ? 'mt-6' : ''
              }`}
            >
              <Link to="/contact/">
                <button
                  type="button"
                  tabIndex="-1"
                  className={`button ${
                    windowWidth < 900 ? 'is-medium' : 'is-large'
                  } is-rounded has-background-primary has-text-white with-animation`}
                >
                  <p className="bold button-text">Contact Us</p>
                </button>
              </Link>
              {includeRequestADemo && (
                <Link to="/demo/">
                  <button
                    type="button"
                    tabIndex="-1"
                    className={`button ${
                      windowWidth < 900 ? 'is-medium' : 'is-large'
                    } is-rounded has-background-dark has-text-white with-animation`}
                  >
                    <p className="bold button-text">Request A Demo</p>
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ContactUs.propTypes = {
  catchPhrase: PropTypes.string,
  sectionClass: PropTypes.string,
  includeRequestADemo: PropTypes.bool,
}

export default ContactUs
