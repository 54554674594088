import React from 'react'
import ContactUs from './ContactUs'

const WithCallToAction = ({
  children,
  sectionClass,
  catchPhrase = 'Ready to take the next steps?',
}) => (
  <>
    {children}
    <ContactUs catchPhrase={catchPhrase} sectionClass={sectionClass} />
  </>
)

export default WithCallToAction
