/* eslint-disable jsx-a11y/label-has-associated-control */
import { Formik } from 'formik'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Download, File, Globe, Mail, User } from 'react-feather'
import { toast, ToastContainer } from 'react-toastify'
import * as Yup from 'yup'
import Layout from '../components/Layout'
import WithCallToAction from '../components/WithCallToAction'
import { decodeHtml } from '../utils/helpers'

export const FileTemplate = ({ title, acf: { content }, localFile, date }) => {
  const { url, size, extension } = localFile
  const [agreementError, setAgreementError] = useState(null)

  return (
    <WithCallToAction>
      <ToastContainer />
      <div className="container mt-81 mb-section-small">
        <section className="section">
          <div className="content">
            <div className="columns">
              <div className="column is-two-thirds vertical-list">
                <File size={64} />
                <h2 className="has-text-dark">{decodeHtml(title)}</h2>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
              <div className="column">
                <div className="p-4 br-10 box">
                  <Formik
                    initialValues={{
                      name: '',
                      company: '',
                      email: '',
                      agreement: false,
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().max(100, 'Name is too long!'),
                      email: Yup.string()
                        .email('Email is invalid')
                        .max(150, 'Email is too long!'),
                      company: Yup.string().max(100, 'Company is too long!'),
                    })}
                    onSubmit={async (values, actions) => {
                      if (
                        values.name.length &&
                        values.company.length &&
                        values.email.length &&
                        !values.agreement
                      ) {
                        setAgreementError('You must agree before downloading')
                        actions.setSubmitting(false)
                        return
                      }
                      setAgreementError(null)
                      const formData = new FormData()
                      actions.setSubmitting(true)
                      Object.keys(values).forEach(key =>
                        formData.append(key, values[key])
                      )
                      formData.append('sourceUrl', url)
                      formData.append('mediaName', title)

                      // email
                      const fileUrlProd =
                        'https://dfs-web-microservice.herokuapp.com/media'
                      // const fileUrlDev = 'http://localhost:3000/media'

                      try {
                        const res = await fetch(fileUrlProd, {
                          method: 'POST',
                          body: formData,
                        }).then(response => response.json()) // parse response as json

                        if (!res.ok) throw new Error(res.error)

                        /* trigger download */

                        // Create an invisible anchor element
                        const a = document.createElement('a')
                        a.style.display = 'none'
                        document.body.appendChild(a)

                        // Set the HREF to a Blob representation of the data to be downloaded
                        a.href = res.sourceUrl

                        // Use download attribute to set set desired file name
                        a.setAttribute('download', res.sourceUrl)

                        // Trigger the download by simulating click
                        a.click()

                        // Cleanup
                        if (typeof window !== 'undefined') {
                          window.URL.revokeObjectURL(a.href)
                        }
                        document.body.removeChild(a)

                        actions.resetForm()
                      } catch (error) {
                        console.error('error: ', error.message)
                        actions.setSubmitting(false)
                        toast.error(
                          'Something went wrong... Please try again later.',
                          {
                            position: toast.POSITION.TOP_CENTER,
                            closeButton: (
                              <button
                                type="button"
                                tabIndex="-1"
                                className="delete"
                                aria-label="delete"
                              />
                            ),
                          }
                        )
                      }
                    }}
                    render={({
                      errors,
                      touched,
                      values,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      isSubmitting,
                    }) => (
                      <form name="media-download-form" onSubmit={handleSubmit}>
                        <div className="field">
                          <label className="label ">Name</label>
                          <div className="control has-icons-left">
                            <input
                              id="name"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              className={`input ${
                                touched.name && errors.name ? 'is-danger' : ''
                              }`}
                              type="text"
                              placeholder="Full Name"
                            />
                            <span className="icon is-small is-left">
                              <User />
                            </span>
                          </div>
                          {touched.name && errors.name && (
                            <span className="help is-danger">
                              {errors.name}
                            </span>
                          )}
                        </div>
                        <div className="field">
                          <label className="label ">Email</label>
                          <div className="control has-icons-left">
                            <input
                              id="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              className={`input ${
                                touched.email && errors.email ? 'is-danger' : ''
                              }`}
                              type="email"
                              placeholder="Email"
                            />
                            <span className="icon is-small is-left">
                              <Mail />
                            </span>
                          </div>
                          {touched.email && errors.email && (
                            <span className="help is-danger">
                              {errors.email}
                            </span>
                          )}
                        </div>
                        <div className="field">
                          <label className="label ">Company</label>
                          <div className="control has-icons-left">
                            <input
                              id="company"
                              name="company"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.company}
                              className={`input ${
                                touched.company && errors.company
                                  ? 'is-danger'
                                  : ''
                              }`}
                              type="text"
                              placeholder="Company"
                            />
                            <span className="icon is-small is-left">
                              <Globe />
                            </span>
                          </div>
                          {touched.company && errors.company && (
                            <span className="help is-danger">
                              {errors.company}
                            </span>
                          )}
                        </div>
                        <div className="field">
                          <label className="checkbox">
                            <p className="mb-0">
                              I agree to the{' '}
                              <Link to="/privacy-policy/">
                                terms and conditions
                              </Link>
                            </p>
                            <input
                              id="agreement"
                              type="checkbox"
                              name="agreement"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.agreement}
                              checked={values.agreement}
                            />
                            <span className="checkmark" />
                          </label>
                          {agreementError && (
                            <span className="help is-danger">
                              {agreementError}
                            </span>
                          )}
                        </div>
                        <div className="field">
                          <button
                            tabIndex="-1"
                            type="submit"
                            className={`button is-dark ${
                              isSubmitting ? 'is-loading' : ''
                            }`}
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                          >
                            <Download className="pr-4" />
                            <strong>Download</strong>
                          </button>
                        </div>
                      </form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </WithCallToAction>
  )
}

FileTemplate.propTypes = {
  acf: PropTypes.shape({
    content: PropTypes.string,
  }),
}

const FilePage = ({ data }) => {
  const {
    wordpressWpMedia: { ...file },
  } = data

  return (
    <Layout
      seo={{
        pageTitle: file.title,
      }}
    >
      <FileTemplate {...file} />
    </Layout>
  )
}

FilePage.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      featured_media: PropTypes.object,
      acf: PropTypes.object,
    }),
  }),
}

export default FilePage

export const pageQuery = graphql`
  fragment FileFields on wordpress__wp_media {
    id
    slug
    title
    mime_type
    localFile {
      url
      size
      extension
    }
    date(formatString: "MMMM D, YYYY")
    acf {
      isDisplayed
      content
      type
    }
  }
  query FileById($id: String!) {
    wordpressWpMedia(id: { eq: $id }) {
      ...FileFields
    }
  }
`
